/* eslint-disable class-methods-use-this */
import { createApp, ULog, utils } from 'goblin';
import kepler from 'kepler';

export default class App {
  constructor(opts) {
    const { bid = '', pid = '', isDebug = true } = opts;
    this.uLog = new ULog({ bid });
    this.bid = bid;
    this.pid = pid;
    this.isDebug = isDebug;
  }

  init() {
    kepler.install({
      // 项目ID
      serviceId: 'web-h5-mpUCShare',
      isReport: utils.ENV === 'PROD',
      ignoreErrors: ['120001'],
    });
    createApp({
      openInApp: false,
      isDebug: this.isDebug
    }).then(() => {
      this.onInit();
    });
  }

  onInit() {
    this.uLog.sendPV(this.pid);
    // this.uLog.sendPerformance();
    this.event();
  }

  event() {
  }

  sendTrack(eventID, values) {
    this.uLog.sendTrack(`${this.pid}_${eventID}`, values);
  }
}
