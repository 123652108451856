const servers = {
  local: {
    activity: '//activity-gy.qa-ag.56qq.com'
  },
  dev: {
    activity: '//activity-gy.qa-ag.56qq.com'
  },
  qa: {
    activity: '//activity-gy.qa-ag.56qq.com'
  },
  pub: {
    activity: '//activity.56qq.com'
  },
  prod: {
    activity: '//activity.56qq.com'
  },
};

export default servers;
