/* eslint-disable class-methods-use-this,no-new */
import { utils } from 'goblin';
import App from '../../common/js/App';
import './index.less';

class Index extends App {
  onInit() {
    super.onInit();
  }

  event() {
    super.event();
    let hadScroll1000 = false;
    let hadScroll3000 = false;
    const { chn = '3287', ckey = 'CK1414154505288' } = utils.urlParams;
    const downloadUrl = `https://s.56qq.com/hybridOperate/channel/index.html?file=siji&from=${chn}&chn=${chn}`;
    const downloadUrlWx = `http://a.app.qq.com/o/simple.jsp?pkgname=com.wlqq&ckey=${ckey}`;
    $('.button').on('click', () => {
      this.sendTrack('open');
      if (utils.isWX) {
        window.location.href = downloadUrlWx;
      } else {
        window.location.href = 'wlqq.driver://launch';
        setTimeout(() => {
          window.location.href = downloadUrl;
        }, 500);
      }
    });
    $(window).on('scroll', () => {
      if (!hadScroll1000 && $(window).scrollTop() >= 1000) {
        this.sendTrack('scroll_1000');
        hadScroll1000 = true;
      } else if (!hadScroll3000 && $(window).scrollTop() >= 3000) {
        this.sendTrack('scroll_3000');
        hadScroll3000 = true;
      }
    });
  }
}

window.ENV = process.env.DEPLOY_ENV === 'prod' ? 'PROD' : 'DEV';
window.App = new Index({ bid: 'mpUCShare', pid: 'download', isDebug: false });
window.App.init();
