export default function list(opts) {
  const { data, selector } = opts;
  const total = data.length;
  let count = 1;
  data.sort(() => Math.random() > 0.5 ? -1 : 1);
  const listContent = `<div>${data[0]}</div><div>${data[1]}</div>`;
  const html = `<div class="list-scroll" style="-webkit-transition:all 1s">${listContent}</div>`;
  $(selector).html(html);
  const con = $(`${selector} .list-scroll`);

  function slider() {
    setInterval(() => {
      count += 1;
      if (count === total) {
        count = 0;
      }
      con.css('-webkit-transform', 'translate3d(0,-50%,0)');
      let timerCss = setTimeout(() => {
        $(con.children()[0]).remove();
        con.append(`<div>${data[count]}</div>`);
        con.css('-webkit-transition', 'none');
        con.css('-webkit-transform', 'translate3d(0,0,0)');
        timerCss = setTimeout(() => {
          clearTimeout(timerCss);
          con.css('-webkit-transition', 'all 1s');
        }, 100);
      }, 1000);
    }, 2000);
  }

  slider();
}
