const oilData = [{
  img: 'https://cos.56qq.com/driver/invite/jiayou0@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou1@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou2@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou3@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou4@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou5@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou6@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou7@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou8@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/jiayou9@2x.png'
}];

const etcData = [{
  img: 'https://cos.56qq.com/driver/invite/etc0@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc1@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc2@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc3@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc4@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc5@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc6@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc7@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc8@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/etc9@2x.png'
}];
const findData = [{
  img: 'https://cos.56qq.com/driver/invite/ms20@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms21@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms22@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms23@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms24@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms25@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms26@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms27@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms28@2x.png'
}, {
  img: 'https://cos.56qq.com/driver/invite/ms29@2x.png'
}];

export default function forum() {
  const content = $('.forum-content');
  const oilItem = oilData[parseInt(Math.random() * oilData.length, 10)] || oilData[0];
  const etcItem = etcData[parseInt(Math.random() * etcData.length, 10)] || etcData[0];
  const findItem = findData[parseInt(Math.random() * findData.length, 10)] || findData[0];
  const arr = [];
  arr.push(oilItem);
  arr.push(etcItem);
  arr.push(findItem);
  const contentStr = arr.reduce((obj, key, index) => {
    const str = `${obj}<div class="forum-item" data-index="${index}"><img src="${key.img}"></div>`;
    return str;
  }, '');
  content.html(contentStr);
}
