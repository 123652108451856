import { Http } from 'goblin';
import servers from './servers';

class Request extends Http {
  constructor(host) {
    super(servers[process.env.DEPLOY_ENV][host], ['120001'], { loading: true, loadingMask: true });
  }
}

export default Request;
